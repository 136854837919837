import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MuiPaper from '@mui/material/Paper';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MuiButton from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Folio from './Folio';
import VistaPrevia from './VistaPrevia';
import DatosFacturacion from './DatosFacturacion';
import DescargaFactura from './DescargaFactura';
import Box from '@mui/material/Box';

import IntegroPOSLogo from '../../assets/IntegroPOS.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.integropos.com?utm_source=portal_autofacturacion">
        <strong>IntegroPAY México</strong>
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const AppBar = styled(MuiAppBar)({
  position: 'relative',
});

const Layout = styled('div')(({ theme }) => ({
  width: 'auto',
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  [theme.breakpoints.up(600 + theme.spacing(4) * 2)]: {
    width: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
}));

const Stepper = styled(MuiStepper)({
  padding: '24px 0 40px',
});

const Buttons = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Button = styled(MuiButton)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(1),
}));

const steps = ['Folio/Ticket de Venta', 'Vista Previa', 'Datos de Facturación', 'Descarga tu Factura'];

function Facturacion({company, host, folio:folioProp }) {

  const [folio, setFolio] = useState(folioProp);
  const [loading, setLoading] = useState(false);
  const [datosFactura, setDatosFactura] = useState({rfc: '', razonsocial: '', email: '', usocfdi: 'G03', formapago: '01', codigopostal: '', regimenfiscal: '601'});
  const [venta, setVenta] = useState({});
  const [factura, setFactura] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setLoading(true);
    switch (activeStep+1){
      case 1: // VISTA PREVIA
        setLoading(true);
        fetch(`${host}/autofacturacion/${folio}`)
        .then(response => response.json())
        .then(data => {
          console.log('VENTA', data);
          if(data.uuid) {
            setFactura(data);
            setActiveStep(3);
            setLoading(false);
          } else {
            if(!data || !data.id) {cleanData(); alert('Venta Inválida o Cancelada.'); setActiveStep(0); return;}
            else {
              if(data.pagos && data.pagos[0] && data.pagos[0].formadepago) {
                setDatosFactura({...datosFactura, formapago: data.pagos[0].formadepago.clave});
              }
              setVenta(data);
              setActiveStep(1);
              setLoading(false);
            }
          }
        })
        .catch(e => {
          setActiveStep(0);
          setLoading(false);
          alert(e.message);
        });
        break;
      case 2: // DATOS FACTURACION
        setActiveStep(2);
        setLoading(false);
        break;
      case 3: // DESCARGAR FACTURA
        console.log('RECEPTOR', datosFactura);
        fetch(`${host}/autofacturacion`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          body: JSON.stringify({
            id: venta.id,
            receptor_rfc: datosFactura.rfc,
            receptor_nombre: datosFactura.razonsocial,
            receptor_usocfdi: datosFactura.usocfdi,
            receptor_email: datosFactura.email,
            receptor_formapago: datosFactura.formapago,
            receptor_cp: datosFactura.codigopostal,
            receptor_regimenfiscal: datosFactura.regimenfiscal
          })
        })
        .then(response => response.json())
        .then(data => {
          console.log('FACTURA', data);
          if(!data.uuid) {
            setActiveStep(2);
            alert(data.data.error);
            setLoading(false);
          } else {
            setFactura(data);
            setActiveStep(3);
            setLoading(false);
          }
        })
        .catch(e => {
          setActiveStep(2);
          setLoading(false);
          alert(e.message);
        });
        break;
      default:
        console.error('Ya no hay mas pasos.');
        cleanData();
        return;
    }
  }, [activeStep, folio, host, datosFactura, venta]);

  const handleBack = () => {
    setLoading(true);
    switch (activeStep-1) {
      case 0: // FOLIO
        cleanData();
        setActiveStep(0);
        setLoading(false);
        break;
      case 1: // VISTA PREVIA
        setDatosFactura({...datosFactura, rfc: '', razonsocial: '', email: '', usocfdi: 'G03', formapago: '01', codigopostal: '', regimenfiscal: '601'});
        setActiveStep(1);
        setLoading(false);
        break;
      case 2: // DATOS FACTURACION
        setFactura({});
        setActiveStep(2);
        setLoading(false);
        break;
      default:
        console.error('Ya no hay mas pasos.');
        cleanData();
        return;
    }
  };

  const cleanData = () => {
    setLoading(true);
    //setFolio('');
    setVenta({});
    setDatosFactura({rfc: '', razonsocial: '', usocfdi: 'G03', formapago: '01', codigopostal: '', regimenfiscal: '601'});
    setFactura({});
    setLoading(false);
  }

  useEffect(() => {
    // initial
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="primary">
        <Toolbar>
          <img src={IntegroPOSLogo} alt="IntegroERP + POS" />
          <Typography variant="h6" color="inherit" noWrap>
            &nbsp; {company}
          </Typography>
        </Toolbar>
      </AppBar>
      <Layout >
        <Paper >
          <Typography component="h1" variant="h4" align="center">
            Portal de AutoFacturación CFDI
          </Typography>
          <Stepper activeStep={activeStep} >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {loading ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <React.Fragment>
                {activeStep === 0 && <Folio folio={folio} onChange={setFolio} />}
                {activeStep === 1 && <VistaPrevia data={venta} />}
                {activeStep === 2 && <DatosFacturacion data={venta} datosFactura={datosFactura} onChange={(field, value) => setDatosFactura({...datosFactura, [field]: value})} />}
                {activeStep === 3 && <DescargaFactura data={factura} />}
                <Buttons>
                  {activeStep > 0 && activeStep < 3 && (
                    <Button onClick={handleBack} >
                      Regresar
                    </Button>
                  )}
                  {activeStep !== 3 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 2 ? 'Timbrar Factura' : 'Continuar'}
                    </Button>
                  )}
                  
                </Buttons>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Layout>
    </React.Fragment>
  );
}

export default Facturacion;
