import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const UsosDelCFDI = {
  "G01": "Adquisición de mercancías.",
  //"G02": "Devoluciones, descuentos o bonificaciones.",
  "G03": "Gastos en general.",
  //"I01": "Construcciones.",
  //"I02": "Mobiliario y equipo de oficina por inversiones.",
  //"I03": "Equipo de transporte.",
  //"I04": "Equipo de computo y accesorios.",
  //"I05": "Dados, troqueles, moldes, matrices y herramental.",
  //"I06": "Comunicaciones telefónicas.",
  //"I07": "Comunicaciones satelitales.",
  //"I08": "Otra maquinaria y equipo.",
  //"D01": "Honorarios médicos, dentales y gastos hospitalarios.",
  //"D02": "Gastos médicos por incapacidad o discapacidad.",
  //"D03": "Gastos funerales.",
  //"D04": "Donativos.",
  //"D05": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
  //"D06": "Aportaciones voluntarias al SAR.",
  //"D07": "Primas por seguros de gastos médicos.",
  //"D08": "Gastos de transportación escolar obligatoria.",
  //"D09": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
  //"D10": "Pagos por servicios educativos (colegiaturas).",
  "S01": "Sin efectos fiscales.  "
};

const RegimenesFiscales = {
  "601": "General de Ley Personas Morales",
  "603": "Personas Morales con Fines no Lucrativos",
  "605": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
  "606": "Arrendamiento",
  "607": "Régimen de Enajenación o Adquisición de Bienes",
  "608": "Demás ingresos",
  "610": "Residentes en el Extranjero sin Establecimiento Permanente en México",
  "611": "Ingresos por Dividendos (socios y accionistas)",
  "612": "Personas Físicas con Actividades Empresariales y Profesionales",
  "614": "Ingresos por intereses",
  "615": "Régimen de los ingresos por obtención de premios",
  "616": "Sin obligaciones fiscales",
  "620": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  "621": "Incorporación Fiscal",
  "622": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  "623": "Opcional para Grupos de Sociedades",
  "624": "Coordinados",
  "625": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  "626": "Régimen Simplificado de Confianza"
};

const regimenesFiscales = Object.keys(RegimenesFiscales).map(key => ({label: `${key} - ${RegimenesFiscales[key]}`, value: key}));
const usosDelCFDI = Object.keys(UsosDelCFDI).map(key => ({label: `${key} - ${UsosDelCFDI[key]}`, value: key}));

export default function DatosFacturacion(props) {
  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Ingresa tus Datos de Facturación
      </Typography>
      <ul variant="h6">
        <li><strong>RFC:</strong> 12 o 13 dígitos, sin guiones ni espacios.</li>
        <li><strong>Razón Social:</strong> Nombre tal cual aparece en tu constancia (sin SA de CV, SAPI, etc)</li>
        <li><strong>Código Postal:</strong> Código postal que aparece en tu constancia de situación fiscal.</li>
        <li><strong>Uso del CFDI:</strong> Opción que describa la utilidad que darás a tu comprobante.</li>
        <li><strong>Régimen Fiscal:</strong> Régimen fiscal que aparece en tu constancia de situación fiscal.</li>
      </ul>
      <hr />
      <br />
      <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            size='small'
            autoComplete="email"
            value={props.datosFactura.email}
            onChange={e => props.onChange('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="rfc"
            name="rfc"
            label="R.F.C."
            fullWidth
            size='small'
            autoComplete="rfc"
            value={props.datosFactura.rfc}
            onChange={e => props.onChange('rfc', e.target.value.toUpperCase())}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <TextField
            required
            id="razonSocial"
            name="razonSocial"
            label="Razón Social"
            fullWidth
            size='small'
            autoComplete="razonsocial"
            value={props.datosFactura.razonsocial}
            onChange={e => props.onChange('razonsocial', e.target.value.toUpperCase())}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          {/*<InputLabel id="codigoPostalLabel">Código Postal</InputLabel>*/}
          <TextField
            required
            id="codigopostal"
            name="codigoPostal"
            label="Código Postal"
            size='small'
            fullWidth
            autoComplete="codigopostal"
            value={props.datosFactura.codigopostal}
            onChange={e => props.onChange('codigopostal', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <InputLabel id="usoCFDIlabel">Uso del CFDI</InputLabel>
          <Select
            labelId="usoCFDIlabel"
            id="usoCFDI"
            size='small'
            fullWidth
            value={props.datosFactura.usocfdi}
            onChange={e => props.onChange('usocfdi', e.target.value)}
          >
            {
              usosDelCFDI.map((u, i) => (<MenuItem key={i} value={u.value}>{u.label}</MenuItem>))
            }
          </Select>
        </Grid>
        {
          (props.data.pagos && props.data.pagos.length>0  && 
            <Grid item xs={12} sm={4} md={4}>
              <InputLabel id="formaPagolabel">Forma de Pago</InputLabel>
              <Select
                labelId="formaPagolabel"
                id="formaPago"
                select="small"
                size='small'
                fullWidth
                value={props.data.pagos[0].formadepago.clave}
                onChange={e => props.onChange('formapago', e.target.value)}
              >
                {
                  props.data.pagos.map((p, i) => (<MenuItem key={i} value={p.formadepago.clave}>{p.formadepago.nombre}</MenuItem>))
                }
              </Select>
            </Grid>
          )
        }
        <Grid item xs={12} sm={4} md={4}>
          <InputLabel id="regimenFiscalLabel">Régimen Fiscal</InputLabel>
          <Select
            labelId="regimenFiscalLabel"
            id="regimenfiscal"
            size='small'
            fullWidth
            value={props.datosFactura.regimenfiscal}
            onChange={e => props.onChange('regimenfiscal', e.target.value)}
          >
            {
              regimenesFiscales.map((u, i) => (<MenuItem key={i} value={u.value}>{u.label}</MenuItem>))
            }
          </Select>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
